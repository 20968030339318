export const GAS_STATION_ACTIONS = {
  GET_GAS_STATION: 'ADMIN_GET_GAS_STATION',
  GAS_STATION_ON_SUCCESS: 'GAS_STATION_ON_SUCCESS',
  GAS_STATION_ON_ERROR: 'GAS_STATION_ON_ERROR',
  SELECT_GAS_STATION: 'ADMIN_SELECT_GAS_STATION',
  DESELECT_GAS_STATION: 'ADMIN_DESELECT_GAS_STATION',
  CLEAR_GAS_STATION: 'ADMIN_CLEAR_GAS_STATION',
  CREATE_GAS_STATION: 'ADMIN_POST_GAS_STATION',
  CREATE_GAS_STATION_SUCCESS: 'ADMIN_POST_GAS_STATION_SUCCESS',
  CREATE_GAS_STATION_ERROR: 'ADMIN_POST_GAS_STATION_ERROR',
  EDIT_GAS_STATION: 'ADMIN_PUT_GAS_STATION',
  EDIT_GAS_STATION_SUCCESS: 'ADMIN_PUT_GAS_STATION_SUCCESS',
  EDIT_GAS_STATION_ERROR: 'ADMIN_PUT_GAS_STATION_ERROR',
  DELETE_GAS_STATION: 'ADMIN_DELETE_GAS_STATION',
  UNDELETE_GAS_STATION: 'ADMIN_UNDELETE_GAS_STATION',
  UNDELETE_GAS_STATION_ERROR: 'ADMIN_UNDELETE_GAS_STATION_ERROR',
  MAKE_GAS_STATION_PAYMENT: 'ADMIN_GAS_STATION_PAYMENT',
  MAKE_GAS_STATION_PAYMENT_ERROR: 'ADMIN_GAS_STATION_PAYMENT_ERROR',
  GET_GAS_STATION_NO_RECEIVABLES: 'GET_GAS_STATION_NO_RECEIVABLES',
  GET_GAS_STATION_BY_ID: 'GET_GAS_STATION_BY_ID',
  GET_GAS_STATION_BY_ID_SUCCESS: 'GET_GAS_STATION_BY_ID_SUCCESS',
  GET_GAS_STATION_BLACK_LIST: 'GET_GAS_STATION_BLACK_LIST',
  CLEAR_EDITED_GAS_STATION: 'CLEAR_EDITED_GAS_STATION',
};

export const getGasStations = params => ({
  type: GAS_STATION_ACTIONS.GET_GAS_STATION,
  payload: { params },
});

export const getGasStationsSuccess = payload => ({
  type: GAS_STATION_ACTIONS.GAS_STATION_ON_SUCCESS,
  payload,
});

export const getGasStationsError = message => ({
  type: GAS_STATION_ACTIONS.GAS_STATION_ON_ERROR,
  payload: { message },
});

export const onSelectGasStation = currentGasStation => ({
  type: GAS_STATION_ACTIONS.SELECT_GAS_STATION,
  payload: { currentGasStation },
});

export const onDeselectGasStation = () => ({
  type: GAS_STATION_ACTIONS.DESELECT_GAS_STATION,
});

export const clearGasStation = () => ({
  type: GAS_STATION_ACTIONS.CLEAR_GAS_STATION,
});

export const createGasStation = (gasStation, params) => ({
  type: GAS_STATION_ACTIONS.CREATE_GAS_STATION,
  payload: { gasStation, params },
});

export const createGasStationSuccess = message => ({
  type: GAS_STATION_ACTIONS.CREATE_GAS_STATION_SUCCESS,
  payload: { message },
});

export const createGasStationError = message => ({
  type: GAS_STATION_ACTIONS.CREATE_GAS_STATION_ERROR,
  payload: { message },
});

export const editGasStation = (id, gasStation, params) => ({
  type: GAS_STATION_ACTIONS.EDIT_GAS_STATION,
  payload: { id, gasStation, params },
});

export const editGasStationSuccess = message => ({
  type: GAS_STATION_ACTIONS.EDIT_GAS_STATION_SUCCESS,
  payload: { message },
});

export const editGasStationError = message => ({
  type: GAS_STATION_ACTIONS.EDIT_GAS_STATION_ERROR,
  payload: { message },
});

export const deleteGasStation = (gasStation, params) => ({
  type: GAS_STATION_ACTIONS.DELETE_GAS_STATION,
  payload: { gasStation, params },
});

export const undeleteGasStationRequest = (id, params) => ({
  type: GAS_STATION_ACTIONS.UNDELETE_GAS_STATION,
  payload: { id, params },
});

export const undeleteGasStationError = message => ({
  type: GAS_STATION_ACTIONS.UNDELETE_GAS_STATION_ERROR,
  payload: { message },
});

export const makeGasStationPayment = (data, params) => ({
  type: GAS_STATION_ACTIONS.MAKE_GAS_STATION_PAYMENT,
  payload: { data, params },
});

export const makeGasStationPaymentError = message => ({
  type: GAS_STATION_ACTIONS.MAKE_GAS_STATION_PAYMENT_ERROR,
  payload: { message },
});

export const getGasStationsNoReceivables = params => ({
  type: GAS_STATION_ACTIONS.GET_GAS_STATION_NO_RECEIVABLES,
  payload: { params },
});

export const getGasStationById = gasStation => ({
  type: GAS_STATION_ACTIONS.GET_GAS_STATION_BY_ID,
  payload: { gasStation },
});

export const getGasStationByIdSuccess = gasStation => ({
  type: GAS_STATION_ACTIONS.GET_GAS_STATION_BY_ID_SUCCESS,
  payload: { gasStation },
});

export const getGasStationBlackList = (orgId, params) => ({
  type: GAS_STATION_ACTIONS.GET_GAS_STATION_BLACK_LIST,
  payload: { orgId, params },
});

export const clearEditedGasStation = () => ({
  type: GAS_STATION_ACTIONS.CLEAR_EDITED_GAS_STATION,
});
