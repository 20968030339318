import produce from 'immer';

import { GAS_STATION_ACTIONS } from './actions';

const INIT_STATE = {
  gasStations: [],
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  success: false,
  loading: false,
  message: '',
  error: null,
  currentGasStation: null,
  gasStation: null,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GAS_STATION_ACTIONS.GET_GAS_STATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case GAS_STATION_ACTIONS.GAS_STATION_ON_SUCCESS: {
        draft.loading = false;
        draft.gasStations = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = null;
        break;
      }

      case GAS_STATION_ACTIONS.GAS_STATION_ON_ERROR: {
        draft.loading = false;
        draft.gasStations = [];
        draft.error = action.payload.message;
        break;
      }

      case GAS_STATION_ACTIONS.SELECT_GAS_STATION: {
        draft.currentGasStation = action.payload.currentGasStation;
        break;
      }

      case GAS_STATION_ACTIONS.DESELECT_GAS_STATION: {
        draft.currentGasStation = null;
        break;
      }

      case GAS_STATION_ACTIONS.CLEAR_GAS_STATION: {
        draft.gasStations = [];
        draft.currentGasStation = null;
        break;
      }

      case GAS_STATION_ACTIONS.CREATE_GAS_STATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case GAS_STATION_ACTIONS.CREATE_GAS_STATION_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.message = action.payload.message;
        break;
      }

      case GAS_STATION_ACTIONS.CREATE_GAS_STATION_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case GAS_STATION_ACTIONS.EDIT_GAS_STATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case GAS_STATION_ACTIONS.EDIT_GAS_STATION_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.message = action.payload.message;
        break;
      }

      case GAS_STATION_ACTIONS.EDIT_GAS_STATION_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case GAS_STATION_ACTIONS.DELETE_GAS_STATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case GAS_STATION_ACTIONS.GET_GAS_STATION_NO_RECEIVABLES: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case GAS_STATION_ACTIONS.GET_GAS_STATION_BY_ID: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case GAS_STATION_ACTIONS.GET_GAS_STATION_BY_ID_SUCCESS: {
        draft.loading = false;
        draft.gasStation = action.payload.gasStation;
        draft.error = '';
        break;
      }

      case GAS_STATION_ACTIONS.GET_GAS_STATION_BLACK_LIST: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case GAS_STATION_ACTIONS.CLEAR_EDITED_GAS_STATION: {
        draft.gasStation = null;
        break;
      }

      default:
        return state;
    }
  });
};
